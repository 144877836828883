import React, { useRef, useState } from 'react'

import type { VideoProps } from '@/types/components/video'
import ResponsiveImage from './ResponsiveImage'
import './video.scss'

const Video: React.FC<VideoProps> = ({
  videoUrl,
  videoType,
  dialogueThumbnail,
  includeControls,
  loopVideo,
  captionsUrl,
  backupImage,
  classes,
  isFirstSection = false,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const isBackgroundVideo = videoType === 'background'

  const handlePlayPause = () => {
    if (!videoRef.current) return

    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }

    setIsPlaying(!isPlaying)
  }

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        crossOrigin={`${!isBackgroundVideo && captionsUrl ? 'anonymous' : ''}`}
        autoPlay={isBackgroundVideo}
        muted={isBackgroundVideo}
        loop={isBackgroundVideo && loopVideo}
        controls={isPlaying && !isBackgroundVideo && includeControls}
        className={classes}
        playsInline
        poster={
          !isBackgroundVideo ? dialogueThumbnail?.desktopImage?.asset.url : ''
        }
      >
        <source src={videoUrl} />
        {!isBackgroundVideo ? (
          <track kind="captions" src={captionsUrl} srcLang="en" default />
        ) : null}
        <ResponsiveImage
          document={backupImage}
          overrides={{
            desktopAspectRatio: '1/1',
            mobileAspectRatio: '1/1',
            loading: isFirstSection ? 'eager' : 'lazy',
            customSizes: [640, 768, 1024, 1200],
          }}
        />
      </video>
      {!isBackgroundVideo && !isPlaying && (
        <button onClick={handlePlayPause} className="video-play-button">
          <div className="triangle-right"></div>
        </button>
      )}
    </div>
  )
}

export default Video
